import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";

import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Collapse, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { addLeadApi, editLeadApi, getAllQuestionsList } from "../../api/admin";
import { getOrganizationUserList } from "../../api/user";
import { useJobOrganization } from "../../common/hooks";
import { setAPIKey } from "../../redux/api/api.action";
import { setCustomerLeadInfo } from "../../redux/lead/lead.action";
import { customerDetailsRoute } from "../../utils/constants/constants";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from "../../utils/displayInfoMessage";
import googleClientId from "../../utils/googleAPIKey";
import BreadcrumbBar from "../breadcrumb/Breadcrumb.pages";
import SmallLoader from "../loader/smallLoader";
import { QUESTION_TYPE } from "../setting/SetupQuestion/v2/utils/helper";
import CustomerInformation from "./components/customerInformation.component";
import { validateLeadForm } from "./utils/helper";

const { Panel } = Collapse;

function LeadForm(props) {
  const location = useLocation();
  const { handleDistance } = useJobOrganization();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const allLeadSources = useSelector((state) => state.organization.leadSources);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googleAPIKey = useSelector((state) => state.api.googleKey);
  const [customerDetails, setCustomerDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [isNewLead, setIsNewLead] = useState(false);
  const [isUpdatingLead, setIsUpdatingLead] = useState(false);
  const { id } = useSelector((state) => state.user);
  const [selectedAssignedUser, setSelectedAssignedUser] = useState([]);
  const [deletedUser, setDeletedUser] = useState([]);
  const [allOrganizationUserList, setAllOrganizationUserList] = useState([]);
  const [sameAsAddress, setSameAsAddress] = useState(true);
  const addNewProject = location.pathname?.includes("add-project");
  const [addProjectDetails, setAddProjectDetails] = useState({});

  const fetchOrganizationQuestions = useCallback(async () => {
    const response = await getAllQuestionsList(QUESTION_TYPE.all);
    if (response.remote === "success") {
      setCustomerDetails((prevState) => ({
        ...prevState,
        questionsAnswers: response.data.questions.map((question) => ({
          _id: question._id,
          answer: question.defaultValue ?? [],
          question,
        })),
      }));
    }
  }, []);

  useEffect(() => {
    if (props.customerDetails) {
      setCustomerDetails(props.customerDetails);
    } else {
      setIsNewLead(true);
      fetchOrganizationQuestions();
    }
  }, [fetchOrganizationQuestions, props.customerDetails]);

  const getAllUserList = async () => {
    const data = await getOrganizationUserList();
    if (data.remote === "success") {
      setAllOrganizationUserList(data.data);
    }
  };
  useEffect(() => {
    getAllUserList();
  }, []);

  useEffect(() => {
    if (customerDetails?.assignee) {
      setSelectedAssignedUser(customerDetails.assignee);
    }
  }, [customerDetails]);

  useEffect(() => {
    if (location.pathname.includes("add-lead") && allOrganizationUserList) {
      setSelectedAssignedUser([id]);
    }
  }, [location, allOrganizationUserList, id]);

  const fetchApiKeys = useCallback(async () => {
    if (!googleAPIKey) {
      const googleKey = await googleClientId();
      dispatch(setAPIKey({ googleKey }));
    }
  }, [dispatch, googleAPIKey]);

  useEffect(() => {
    fetchApiKeys();
  }, [fetchApiKeys]);
  const handlePersonalInformation = (field, value) => {
    if (addNewProject) {
      setAddProjectDetails((prev) => ({
        ...prev,
        [field]: value || "",
      }));
    } else {
      setCustomerDetails((prev) => ({
        ...prev,
        [field]: value || "",
        isSoftwareFollowUp: false,
      }));
    }
  };

  const handleSpouseInformation = (field, value) => {
    setCustomerDetails({
      ...customerDetails,
      spouseInformation: {
        ...(customerDetails.spouseInformation || {}),
        [field]: value,
      },
    });
  };

  const handleAddService = (service) => {
    if (!addNewProject) {
      const servicesList = [...(customerDetails.services || []), service];
      const output = [...new Map(servicesList.map((o) => [o, o])).values()];
      setCustomerDetails({
        ...customerDetails,
        services: output || [],
      });
    } else {
      const servicesList = [...(addProjectDetails.services || []), service];
      const output = [...new Map(servicesList.map((o) => [o, o])).values()];
      setAddProjectDetails((prev) => ({
        ...prev,
        services: output || [],
      }));
    }
  };

  const handleRemoveService = (service) => {
    if (!addNewProject) {
      setCustomerDetails({
        ...customerDetails,
        services: (customerDetails.services || []).filter(
          (serviceItem) => serviceItem._id !== service._id,
        ),
      });
    } else {
      setAddProjectDetails((prev) => ({
        ...prev,
        services: (prev?.service || []).filter(
          (serviceItem) => serviceItem?._id !== service?._id,
        ),
      }));
    }
  };

  const handleQuestionChange = (questionId) => (value) => {
    const questions = [...(customerDetails.questionsAnswers || [])].map(
      (question) => {
        if (question._id === questionId) {
          question.answer = value;
        }
        return question;
      },
    );
    setCustomerDetails({ ...customerDetails, questionsAnswers: questions });
  };

  const addUpdateLead = async () => {
    setIsUpdatingLead(true);
    let content = "";
    if (isNewLead) {
      content = "Adding...";
    } else {
      content = "Updating...";
    }
    displayLoadingMessage(content);
    setErrors({});
    const { errors, isValid } = validateLeadForm({
      details: customerDetails,
      selectedValue: props.dropdownValue,
      newProject: addNewProject,
    });
    if (!isValid) {
      if (errors.email) {
        setErrors(errors);
      } else {
        setErrors(errors);
        content = "Please check the fields, validation failed!";
        displayErrorMessage(content, 3);
      }
    } else {
      const tempService = customerDetails?.services?.map(
        (service) => service._id,
      );
      const tempQuestions = customerDetails?.questionsAnswers?.map(
        (question) => ({
          _id: question._id,
          question: question.question ? question.question._id : question._id,
          answer: question.answer,
        }),
      );

      const payload = {
        fullName: customerDetails.fullName,
        email: customerDetails.email,
        contactNo: customerDetails.contactNo,
        address: customerDetails.address,
        projectName:
          customerDetails.newProjectName?.replace(/^\s+|\s+$/g, "") ||
          customerDetails.projectName?.replace(/^\s+|\s+$/g, ""),
        projectAddress:
          customerDetails.newProjectAddress || customerDetails.projectAddress,
        id: customerDetails._id,
        activeStatus: customerDetails.activeStatus,
        services: tempService,
        questionsAnswers: tempQuestions,
        spouseInformation: customerDetails.spouseInformation,
        isSoftwareFollowUp: customerDetails.isSoftwareFollowUp,
        leadSource: customerDetails.leadSource || allLeadSources[0]._id,
        leadAssignTo: selectedAssignedUser,
        deletedUser,
      };
      if (!isNewLead) {
        const response = await editLeadApi(payload);
        if (response.remote === "success") {
          content = "Updated successfully";
          displaySuccessMessage(content, 3);
          await handleDistance();
          const newCustomerDetails = {
            ...customerDetails,
            assignee: [...selectedAssignedUser],
          };
          delete newCustomerDetails.newProjectName;
          delete newCustomerDetails.newProjectAddress;
          setCustomerDetails(newCustomerDetails);
          dispatch(setCustomerLeadInfo(newCustomerDetails));
          if (props.getCustomerGetInfo) {
            await props.getCustomerGetInfo();
          }
        }
        setIsUpdatingLead(false);
      } else {
        const response = await addLeadApi(payload);
        setIsUpdatingLead(false);
        if (response.remote === "success") {
          content = "Lead Added successfully";
          displaySuccessMessage(content, 3);
          const route = {
            customerLeadId: response.data._id,
            isScheduledCustomer: false,
          };
          navigate(customerDetailsRoute(route, location?.state), {
            state: { ...location?.state },
          });
        } else {
          displayErrorMessage("Something went wrong, please try again", 3);
        }
      }
    }
    setIsUpdatingLead(false);
  };

  const handleSubmit = async () => {
    addUpdateLead();
  };

  if (props.isLoadingCustomer) {
    return <SmallLoader />;
  }
  const handleUserSelect = (selectedUserIds) => {
    setSelectedAssignedUser(selectedUserIds);
  };
  const handleUserDeselect = (deselectedUserId) => {
    setDeletedUser((pre) => [...pre, deselectedUserId]);
  };

  return (
    <>
      {!props.hideBreadcrumbBar && (
        <BreadcrumbBar
          name="Dashboard"
          subname="Add A Lead"
          breaclass="mb-3"
          link="/"
        />
      )}
      <Collapse
        className="ant-crm-card p-2"
        style={{ background: "#fff" }}
        defaultActiveKey={["1"]}
      >
        <Panel
          className="cricle-panel"
          header="Customer Information"
          key="1"
          icon={<UserOutlined />}
          style={{ border: "0px" }}
        >
          <CustomerInformation
            handleUserDeselect={handleUserDeselect}
            allOrganizationUserList={allOrganizationUserList}
            selectedAssignedUser={selectedAssignedUser}
            handleUserSelect={handleUserSelect}
            customerDetails={
              Object.keys(customerDetails).length
                ? customerDetails
                : props.customerDetails
                ? props.customerDetails
                : {}
            }
            handlePersonalInformation={handlePersonalInformation}
            handleSpouseInformation={handleSpouseInformation}
            addService={handleAddService}
            removeService={handleRemoveService}
            handleQuestionChange={handleQuestionChange}
            errors={errors}
            handleSubmit={handleSubmit}
            isNewLead={isNewLead}
            isUpdatingLead={isUpdatingLead}
            setSameAsAddress={setSameAsAddress}
            sameAsAddress={sameAsAddress}
            addNewProject={addNewProject}
            setCustomerDetails={setCustomerDetails}
            addProjectDetails={addProjectDetails}
            setAddProjectDetails={setAddProjectDetails}
            dropdownValue={props.dropdownValue}
          />
        </Panel>
      </Collapse>
      <Modal
        className="modal-radius warning-modal"
        footer={null}
        header={false}
        closable={false}
      >
        <div className="warning-heading">
          <InfoCircleOutlined />
          <h4>Warning!</h4>
        </div>
        <p className="mb-4">
          Email Already exists! Are you really wan&apos;t to add it again?
        </p>
        <div className="warning-btn">
          <Button shape="round" type="link" className="btn-cancel">
            No
          </Button>
          <Button shape="round" type="primary">
            Save anyway
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default LeadForm;
