import urlcat from "urlcat";

import { USER_ROLE } from "../utils/constants/constants";
import { getRole } from "../utils/role";
import api from "./api";
import { transformSuggestionResponse } from "./transform/formula";

export const getSuggestions = async (type, searchString) => {
  switch (type) {
    case "material":
      const response = await api.request({
        url: `/services/search-catelog-by-name?catelogName=${searchString}`,
        method: "GET",
      });
      if (response.remote === "success") {
        return {
          remote: response.remote,
          data: transformSuggestionResponse(response),
        };
      }
      return response;
    default:
      return {
        remote: "success",
        data: [],
      };
  }
};
// new are after 2023-08-16
export const getAllFormula = async (type) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/get-all-formula?type=${type}`;
    }
    return `/v2/formula/organization/get-all-formula?type=${type}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
};
// old are before 2023-08-16
export const getAllOldFormula = async (type) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/get-all-old-formula?type=${type}`;
    }
    return `/v2/formula/organization/get-all-formula?type=${type}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
};

export const createFormula = async (formula) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/formula/add-new-formula";
    }
    return "/v2/formula/organization/add-new-formula";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data: formula,
  });
  return response;
};

export const createFormulaCopy = async (data) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/formula/create-new-copy";
    }
    return "/v2/formula/organization/create-new-copy";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data,
  });
  return response;
};

export const getFormulaById = async (formulaId) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/get-formula-by-id/${formulaId}`;
    }
    return `/v2/formula/organization/get-formula-by-id/${formulaId}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
};

export const getPopulatedFormulaById = async (formulaId) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/get-populated-formula-details-by-id/${formulaId}`;
    }
    return `/v2/formula/organization/get-populated-formula-details-by-id/${formulaId}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
};

export const updateFormula = async (formulaId, data) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/update-formula-by-id/${formulaId}`;
    }
    return `/v2/formula/organization/update-formula-by-id/${formulaId}`;
  };
  const response = await api.request({
    url: url(),
    method: "PUT",
    data,
  });
  return response;
};

export const addFormulaNewElement = async (formulaId, data) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/add-formula-new-element/${formulaId}`;
    }
    return `/v2/formula/organization/add-formula-new-element/${formulaId}`;
  };
  const response = await api.request({
    url: url(),
    method: "PUT",
    data,
  });
  return response;
};

export const deleteFormula = async (formulaId) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/delete-formula-by-id/${formulaId}`;
    }
    return `/v2/formula/organization/delete-formula-by-id/${formulaId}`;
  };
  const response = await api.request({
    url: url(),
    method: "DELETE",
  });
  return response;
};

export const deleteFormulaByIdArray = async (ids) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/formula/delete-formula-by-id-array";
    }
    return "/v2/formula/organization/delete-formula-by-id-array";
  };
  const response = await api.request({
    url: url(),
    method: "DELETE",
    data: {
      ids,
    },
  });
  return response;
};

export const searchFormulaByNameV3 = async (searchString, query) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/search-formula-by-name-v3/${searchString}`;
    }
    return `/v2/formula/organization/search-formula-by-name-v3/${searchString}`;
  };
  const response = await api.request({
    url: urlcat(url(), query),
    method: "GET",
  });
  return response;
};

export const deleteUserEstimation = async (estimationId) => {
  const response = await api.request({
    url: `/v2/user-estimation/delete-user-estimation/${estimationId}`,
    method: "DELETE",
  });
  return response;
};

export const publishChanges = async (formulaId) => {
  const response = await api.request({
    url: `/v2/formula/publish-changes/${formulaId}`,
    method: "PUT",
  });
  return response;
};
export const unpublishChanges = async (formulaId) => {
  const response = await api.request({
    url: `/v2/formula/unpublish-changes/${formulaId}`,
    method: "PUT",
  });
  return response;
};

export const getTopNMostUsedFormula = async (limit) => {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/get-top-n-most-used-formula?limit=${limit}`;
    }
    return `/v2/formula/organization/get-top-n-most-used-formula-for-organization?limit=${limit}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
};

export const increaseViewCount = async (id) => {
  const url = () => {
    const role = getRole();
    if (role === USER_ROLE.superAdmin) {
      return `/v2/formula/increase-total-view-count/${id}`;
    }
    return `/v2/formula/organization/increase-total-view-count/${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "PUT",
  });
  return response;
};

export const getFormulaCategoryAPI = async () => {
  const response = await api.request({
    url: "/v3/formula/get-formula-category",
    method: "GET",
  });
  return response;
};

export const createFormulaCategoryAPI = async (data) => {
  const response = await api.request({
    url: "/v3/formula/create-formula-category",
    method: "POST",
    data,
  });
  return response;
};
export const getFormulaSubCategoryAPI = async (categoryId) => {
  const response = await api.request({
    url: `/v3/formula/get-formula-sub-category/${categoryId}`,
    method: "GET",
  });
  return response;
};
export const createFormulaSubCategoryAPI = async (data) => {
  const response = await api.request({
    url: "/v3/formula/create-formula-sub-category",
    method: "POST",
    data,
  });
  return response;
};
export const updateOrganizationIsEditFieldAPI = async (data) => {
  const response = await api.request({
    url: `/v3/estimation-templates/organization/edit-by-organization/${data.estimationTemplateId}`,
    method: "PUT",
    data,
  });
  return response;
};
export const updateEstimationTemplateImageAPI = async (
  estimationTemplateId,
  data,
) => {
  const response = await api.request({
    url: urlcat(
      "/v3/estimation-templates/organization/:estimationTemplateId/image",
      { estimationTemplateId },
    ),
    method: "PUT",
    data,
  });
  return response;
};

export const getPopulatedFormulaByIdV4API = async (templateId) => {
  const response = api.request({
    url: urlcat(
      "/v3/estimation-templates/organization/populated-template/:templateId",
      { templateId },
    ),
  });
  return response;
};

export const updateEstimationTemplateElementByElementId = async ({
  templateId,
  payload,
}) => {
  const response = await api.request({
    url: urlcat(
      "/v3/estimation-templates/organization/update-element/:templateId",
      { templateId },
    ),
    method: "PUT",
    data: payload,
  });
  return response;
};
