/* eslint-disable no-useless-escape */
const regex = {
  htmlTags: /<[^>]*>/g,
  base64: /data:[a-z]+\/[a-z]+;base64,/g,
  customInput: /![a-zA-Z]+/g,
  materialInput: /@\{\{[^\}]+\}\}/gi,
  escapeRegex: /[.*+\-?^${}()|[\]\/\\]/g,
  conditionalExpression:
    /(\d+\s*(\*|\/|\+|\-)\s*)+(\d+\s*)\s?[<>=]{1,2}\s?[0-9]+/,
  operationRegex: /[<>=]=?|!=/g,
  markupCustomInputRegex: /\{[^}]*\}/g,
  validURL(str) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // fragment locator
    return !!pattern.test(str);
  },
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  emptyHtml: /^(<p>(&nbsp;)+<\/p>|<p><\/p>)$/g,
  checkSpecialChar(value) {
    const pattern = new RegExp("(?=.*[!@#$%^&*])", "i");
    return !!pattern?.test(value);
  },
  checkUpperCase(value) {
    const pattern = new RegExp("(?=.*[A-Z])");
    return !!pattern?.test(value);
  },
  checkLowerCase(value) {
    const pattern = new RegExp("(?=.*[a-z])");
    return !!pattern?.test(value);
  },
  checkNumber(value) {
    const pattern = new RegExp("(?=.*[0-9])");
    return !!pattern?.test(value);
  },
  currencyNormalizer: /[$,]/gm, // this regex will remove `,` and `$` from currency formatted number e.g. "$5,500.00" will become 5500
};
export default regex;
